<template>
    <div class="app-container parkingDetails">
        <div class="prompt_top" v-if="Object(viewList).status == '待审核'">
            <div style="font-weight: 800;">请您审批 - 停车月卡申请</div>
            <div>
                <el-button type="warning" @click="handlePass">通过</el-button>
                <!-- <el-button type="warning" plain @click="handleReject" v-if="Object(viewList).status !== '已驳回'">驳回</el-button> -->
                <el-button type="warning" plain @click="handleReject">驳回</el-button>
            </div>
        </div>
        <div class="content">
            <div>停车月卡申请</div>
            <div v-if="Object.keys(viewList).length > 0">
                <div class="content-left">
                    <ul>
                        <li>
                            <p>申请ID</p>
                            <p>{{ viewList.applicationNum || '--' }}</p>
                        </li>
                        <li>
                            <p>申请人</p>
                            <p>{{ viewList.userName || '--' }}</p>
                        </li>
                        <li>
                            <p>身份证号</p>
                            <p>{{ viewList.numberId || '--' }}</p>
                        </li>
                        <li>
                            <p>手机号码</p>
                            <p>{{ viewList.phone || '--' }}</p>
                        </li>
                        <li>
                            <p>车牌号</p>
                            <p>{{ viewList.plate || '--' }}</p>
                        </li>
                        <li>
                            <p>月卡标准</p>
                            <p>{{ viewList.monMoney || '--' }}</p>
                        </li>
                        <!-- <li>
                            <p>身份证</p>
                            <p>
                            <div class="image-container">
                                <div class="demo-image__preview">
                                    <el-image style="width: 64px; height: 64px; border-radius: 6px;" :src="IDCard0"
                                        :preview-src-list="IDCardList0">
                                    </el-image>
                                    <div class="overlay">
                                        <span>正面</span>
                                    </div>
                                </div>
                                <div class="demo-image__preview">
                                    <el-image style="width: 64px; height: 64px; border-radius: 6px;" :src="IDCard1"
                                        :preview-src-list="IDCardList1">
                                    </el-image>
                                    <div class="overlay">
                                        <span>反面</span>
                                    </div>
                                </div>
                            </div>
                            </p>
                        </li> -->
                        <li>
                            <p>驾驶证</p>
                            <p>
                            <div class="image-container">
                                <div class="demo-image__preview">
                                    <el-image style="width: 64px; height: 64px; border-radius: 6px;" :src="driverCard0"
                                        :preview-src-list="driverCardList0">
                                    </el-image>
                                    <div class="overlay">
                                        <span>正页</span>
                                    </div>
                                </div>
                                <div class="demo-image__preview">
                                    <el-image style="width: 64px; height: 64px; border-radius: 6px;" :src="driverCard1"
                                        :preview-src-list="driverCardList1">
                                    </el-image>
                                    <div class="overlay">
                                        <span>副页</span>
                                    </div>
                                </div>
                            </div>
                            </p>
                        </li>
                        <li>
                            <p>行驶证</p>
                            <p>
                            <div class="image-container">
                                <div class="demo-image__preview">
                                    <el-image style="width: 64px; height: 64px; border-radius: 6px;" :src="DrivingCard0"
                                        :preview-src-list="DrivingCardList0">
                                    </el-image>
                                    <div class="overlay">
                                        <span>正页</span>
                                    </div>
                                </div>
                                <div class="demo-image__preview">
                                    <el-image style="width: 64px; height: 64px; border-radius: 6px;" :src="DrivingCard1"
                                        :preview-src-list="DrivingCardList1">
                                    </el-image>
                                    <div class="overlay">
                                        <span>副页</span>
                                    </div>
                                </div>
                            </div>
                            </p>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <p>申请时间</p>
                            <p>{{ viewList.applicationTime | dateFormat("YYYY-MM-DD HH:mm:ss") || '--' }}</p>
                        </li>
                        <li>
                            <p>月卡类型</p>  
                            <p>{{ viewList.monthCard  }}</p>
                        </li>
                        <li>
                            <p>所属停车场</p>
                            <p>{{ viewList.parkingName }}</p>
                        </li>
                        <!-- <li>
                            <p>车辆类型</p>  
                            <p>{{ viewList.typeCar == '1' ? "小车" : "大车" || '--' }}</p>
                        </li>
                        <li>
                            <p>所属停车场</p>
                            <p>{{ viewList.areaCode == '1' ? "锦绣园区停车场（一、三期）" : "华萃公寓停车场（二期）" || '--' }}</p>
                        </li> -->
                        <li v-if="viewList.positionDescName !== null">
                            <p>所属楼栋</p>
                            <p>{{ viewList.positionDescName || '--' }}</p>
                        </li>
                        <li v-if="viewList.company !== null">
                            <p>所属企业</p>
                            <p>{{ viewList.company || '--' }}</p>
                        </li>
                        <li>
                            <p>微信支付单号</p>
                            <p>{{ viewList.wxPayId || '--' }}</p>
                        </li>
                        <li>
                            <p>支付时间</p>
                            <p>{{ viewList.wxPayTime | dateFormat("YYYY-MM-DD HH:mm:ss") || '--' }}</p>
                        </li>
                        <li>
                            <p>月卡开始时间</p>
                            <p>{{ viewList.startTime | dateFormat("YYYY-MM-DD HH:mm:ss") || '--' }}</p>
                        </li>
                        <li>
                            <p>首月费用</p>
                            <p>{{ viewList.payMoney || '--' }}</p>
                        </li>
                        <li>
                            <p>操作时间</p>
                            <p>{{ viewList.operatorTime | dateFormat("YYYY-MM-DD HH:mm:ss") || '--' }}</p>
                        </li>
                        <li>
                            <p>操作人</p>
                            <p>{{ viewList.operator || '--' }}</p>
                        </li>
                        <li>
                            <p>状态</p>
                            <p>{{ viewList.status || '--' }}</p>
                        </li>
                        <li v-if="viewList.reasonList.length !== 0">
                            <p>驳回记录</p>
                            <p>
                            <ul class="rejectionRecord">
                                <li v-for="(item, index) in viewList.reasonList" :key="index">
                                    <p><span>{{ viewList.reasonList.length - index }}：</span>{{ item.reason  }}</p>
                                    <p class="Category">操作人：{{ item.operator || '--' }}</p>
                                    <p class="Category">操作时间：{{ item.operatorTime | dateFormat("YYYY-MM-DD HH:mm:ss") || '--' }}</p>
                                </li>
                            </ul>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div>
            <!-- 驳回 弹窗 -->
            <el-dialog title="驳回申请" :visible.sync="dialogVisible" width="30%" :before-close="handleCloseDialog"
                class="Dialog" :close-on-click-modal="false">
                <el-form :model="ruleFormDialog" :rules="rulesDialog" ref="ruleFormDialog" label-width="100px"
                    class="demo-ruleForm" label-position="top">
                    <el-form-item label="驳回原因" prop="cancelReason">
                        <el-input type="textarea" :rows="4" placeholder="请填写驳回原因,字数不超过120字"
                            v-model="ruleFormDialog.cancelReason" maxlength="120">
                        </el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleCloseDialog">关 闭</el-button>
                    <el-button type="primary" @click="handleSubmit">提 交</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
// import {
//     viewCardList,
//     passCardApply,
//     RejectCardList
// } from "@/api/ruge/gsPark/customerService/monthlyCardApplication";

import {
    viewCardList,
    passCardApply,
    RejectCardList
} from "@/api/ruge/lego/parkAffairs/monthlyCardApplication"

import { dateFormat } from "@/filters/index";
import { envInfo } from "@/constants/envInfo";
export default {
    data() {
        return {
            // // 身份证
            // IDCard0: '',
            // IDCardList0: [],
            // IDCard1: '',
            // IDCardList1: [],
            // 驾驶证
            driverCard0: '',
            driverCardList0: [],
            driverCard1: '',
            driverCardList1: [],
            // 行驶证
            DrivingCard0: '',
            DrivingCardList0: [],
            DrivingCard1: '',
            DrivingCardList1: [],

            dialogVisible: false,
            viewList: {},
            ruleFormDialog: {
                cancelReason: '',
            },
            rulesDialog: {
                cancelReason: [
                    { required: true, message: '请输入驳回原因', trigger: 'blur' },
                    { min: 1, max: 120, message: '长度在 1 到 120 个字符', trigger: 'blur' }
                ]
            },
        }
    },
    created() {
        this.getViewList()
    },
    methods: {
        getViewList() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            viewCardList({
                id: this.$route.query.dataId
            }).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === '1') {
                        res.data.status = '待审核'
                    } else if (res.data.status === '2') {
                        res.data.status = '已驳回'
                    } else if (res.data.status === '4') {
                        res.data.status = '已开通'
                    } else if (res.data.status === '0') {
                        res.data.status = '已取消'
                    } else if (res.data.status === '3') {
                        res.data.status = '已通过'
                    }
                    this.viewList = res.data
                    console.log(res.data.imgDlId);
                    console.log(res.data.imgIdCardId);
                    console.log(res.data.imgVlId);
                    // // 身份证号imgIdCardId
                    // this.IDCard0 = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgIdCardId[0] // 正面
                    // this.IDCard1 = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgIdCardId[1]  // 反面
                    // this.IDCardList0[0] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgIdCardId[0]
                    // this.IDCardList0[1] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgIdCardId[1]
                    // this.IDCardList1[0] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgIdCardId[1]
                    // this.IDCardList1[1] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgIdCardId[0]
                    // 驾驶证imgDlId
                    this.driverCard0 = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgDlId[0] // 正面
                    this.driverCard1 = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgDlId[1]  // 反面
                    this.driverCardList0[0] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgDlId[0]
                    this.driverCardList0[1] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgDlId[1]
                    this.driverCardList1[0] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgDlId[1]
                    this.driverCardList1[1] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgDlId[0]
                    // 行驶证imgVlId
                    this.DrivingCard0 = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgVlId[0] // 正面
                    this.DrivingCard1 = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgVlId[1]  // 反面
                    this.DrivingCardList0[0] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgVlId[0]
                    this.DrivingCardList0[1] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgVlId[1]
                    this.DrivingCardList1[0] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgVlId[1]
                    this.DrivingCardList1[1] = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imgVlId[0]

                    loading.close();
                }
            })
        },
        handlePass() {
            this.$confirm('是否通过停车月卡申请?', '提示', {
                confirmButtonText: '确定通过',
                cancelButtonText: '取消',
                type: 'warning',
                // center: true
            }).then(success => {
                this.handleApplication()
            }).catch(error => {
                console.log(error);
            });
        },
        handleApplication() {
            passCardApply({
                id: this.$route.query.dataId
            }).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: '通过成功!'
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
                this.getViewList()
            })
        },
        handleReject() {
            this.dialogVisible = true
        },
        handleSubmit() {
            this.$refs.ruleFormDialog.validate((valid) => {
                if (valid) {
                    RejectCardList({
                        id: this.$route.query.dataId,
                        reason: this.ruleFormDialog.cancelReason
                    }).then((res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '驳回成功!'
                            });
                            this.dialogVisible = false
                            this.$refs.ruleFormDialog.resetFields();
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            })
                        }
                        this.getViewList()
                    })
                }
            })
        },
        handleCloseDialog() {
            this.dialogVisible = false
            this.$refs.ruleFormDialog.resetFields();
        },
    }
}
</script>

<style lang="less" scoped>
.parkingDetails {

    // ---------------图片样式
    .image-container {
        display: flex;
    }

    .demo-image__preview {
        margin-right: 10px;
        flex: 1;
        position: relative;
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 4rem;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 6px 6px;
    }

    .overlay span {
        font-size: 12px;
        text-align: center;
    }

    // ----------------

    .prompt_top {
        border: 1px solid #F3AF00;
        margin-bottom: 10px;
        padding: 24px;

        div:first-child {
            padding-bottom: 32px;
        }
    }

    .content {
        padding: 24px;

        div:first-child {
            // padding-bottom: 12px;
            font-weight: 800;
        }

        div:last-child {
            li {
                padding-top: 24px;

                p {
                    padding-top: 10px;
                }

                p:first-child {
                    font-size: 12px;
                    color: #6B7278;
                }

                p:last-child {
                    font-size: 14px;

                    .rejectionRecord {
                        li {
                            padding: 5px 10px;
                            border-radius: 5px;
                            margin-bottom: 5px;
                            background-color: #efefef;
                            width: 40rem;

                            p {
                                font-size: 14px;
                                color: black;
                                padding-top: 8px;
                            }
                            .Category{
                                font-size: 12px;
                                color: #6B7278;
                            }
                            
                            
                        }
                    }
                }
            }

            display: flex;

            .content-left {
                padding-right: 337px;
            }
        }
    }
}
</style>