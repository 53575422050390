import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 停车月卡申请 - 分页查询
 * @param params
 */
export function getCardList(params) {
    return request({
      url: envInfo.bgApp.customerServicelegoPath + '/MonthlyCard/getMonthlyCardList',
      method: 'get',
      params,
    });
  }

  /**
 * 停车月卡申请 - 查看详情
 * @param params
 */
export function viewCardList(params) {
    return request({
      url: envInfo.bgApp.customerServicelegoPath + '/MonthlyCard/getDetailById',
      method: 'get',
      params,
    });
  }

    /**
 * 停车月卡申请 - 通过
 * @param params
 */
export function passCardApply(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/MonthlyCard/reviewPass',
    method: 'get',
    params,
  });
}

  /**
 * 停车月卡申请 - 驳回
 * @param params
 */
  export function RejectCardList(params) {
    return request({
      url: envInfo.bgApp.customerServicelegoPath + '/MonthlyCard/reviewReject',
      method: 'get',
      params,
    });
  }

    /**
 * 停车月卡申请 - 导出
 * @param params
 */
    export function MonthlyCardExport(params) {
      return request({
        url: envInfo.bgApp.customerServicelegoPath + '/MonthlyCard/export',
        method: 'get',
        params,
      });
    }
        /**
 * 停车月卡申请 - 车场列表
 * @param params
 */
    export function getCarData(params) {
      return request({
        url: envInfo.bgApp.customerlegoPath + '/parkingManage/queryParkingInfoList',
        method: 'get',
        params,
      });
    }